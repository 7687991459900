<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
 

        <banner
          title="SERVICES"
          :breadcrumb="[
             {
              label: 'Dashboard',
            },
           
            {
              label: 'Services',
            }

          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card"
              style="border-radius: 10px; width: 100%; box-shadow: 0px 0px 5px 0px gray"
            >
              <div class="card-body text">
                <!-- start list area  -->
                <div class="row">
                  <div class="col-md-6 col-6">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder">All Services </b>
                    </h4>
                  </div>
                  <div class="col-md-6 col-6 text-end"></div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="margin-top: 7px"
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <!-- <th style="width: 5%">+</th> -->

                          <!-- <th>Code</th>
                             <th>Name</th>
                          <th>Mobile </th>
                          <th> Email</th>
                          <th>Designation</th> -->
                          <th>Service</th>
                          <th class="text-end">Status</th>
                          <!-- <th>Action</th> -->
                        </tr>

                        <tbody class="text">
                          <tr v-for="(service, index) in services" :key="index">
                            <!-- <td>
                              <img
                                style="width: 8px; margin-right: 5px"
                                src="/assets/image/record.png"
                                alt=""
                              />
                            </td> -->
                            <!-- <td>{{service.professional.code}}</td>
                             <td>{{service.professional.name}}</td>
                            <td>{{service.professional.phone}}</td>
                            <td>{{service.professional.email}}</td>
                            <td>----</td> -->
                            <td>{{ service.name }}</td>
                            <td class="text-end">
                              <p
                                v-if="service.status == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  box-shadow: 2px 2px 5px gray;
                                  width: 70px;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  box-shadow: 2px 2px 5px gray;
                                  width: 70px;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

//
<script>
import Spinner from "../../../components/professional/comman/Spinner.vue";
import Banner from "../../../components/professional/comman/Banner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "ProfessionalallServices",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      loading: false,
      services: [],
    };
  },
  methods: {
    loadProfessionalService() {
      this.loading = true;
      this.$axios
        .get("professional/service?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data.data)
          this.services = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
        document.title =this.$store.state.professionalPageTitles.service
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadProfessionalService();
      //   this.loadProfessionalWallet()
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  /* width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 8px gray; */
  /* line-height: 10%; */
  /* margin-left: 13px; */
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px gray;
  background-color: white;
  line-height: 10%;
}
@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}
</style>
